body {
  margin: 0;
  padding: 0;
  font-family: 'Concert One', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bold-text{
  font-weight: bold;
}

.grn-bg{
  margin-top: 2vw;
  background: #5dc8a5
}

.navbar{
  background: #5fc8a5
}

.navbar-font{
  color: blue;
}

.logo{
  padding-right: 40px;
}

.welcome{
  width: 100%;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.row{
  display: flex;
  max-width: 90%;
  margin: auto;
  margin-top: 10px;
}

.column-left{
  width: 20%;
  flex: auto;
  font-weight: bold;
}

.column-right{
  flex: auto;
  width: 70%;
  margin-top: 1em;
}

.about-p{
  font-size: 1.5rem;
}

.tech-logo {
  width: 100%;
  padding: 20px;
}

.tech-logo-container {
  width: 200px;
}

.progress-row{
  display: flex;
  padding-left: 27vw;
}

.progress-h1{
  text-align: left;
  margin-right: auto;
  margin-top: 10px;
}

.experience-row{
  /* display: flex; */
  margin-left: 27vw;
  display: flex;
  flex-wrap: wrap;

}

.experience-div{
  width: 34vw;
  padding: 1.5rem;
}

.experience-h1{
  margin-left: 10px;
}

.white-bg{
  background: #e9ecef;
  color: #057aff;
}

.blue-bg{
  background: #057aff;
  color: white;
}

.bl-txt{
  color: #057aff;
}

.experience-p{
  margin-left: 2vw;
  margin-right: 3vw;
}

.video-tag{
  width: 50vw;
  margin-left: 8vw;
}

.video-div{
  background: #057aff;
  padding: 20px;
  margin-right: 3.5vw;
}

.video-div-gray{
  background: #e9ecef;
  padding: 20px;
  margin-right: 3.5vw;
}

.footer-padding{
  padding-left: 26vw;
}

.my-name{
  position: relative;
  bottom: 40vw;
  left: 8vw;
  font-size: 4vw;
}

.row-resume{
  display: flex;
  width: 90%;
  padding: 5px;
  margin: auto;
}

.column-resume{
  flex: auto;
}

.resume-hr{
  border: 2px black solid;
  width: 80%;
}

.resume-h5-div{
  padding-left: 11vw;
  padding-right: 10vw;
}

.font-1-5{
  font-size: 1.5rem;
}

.resume-ul{
  padding-left: 20px;
}

.resume-topics{
  font-size: 2.5rem;
}

.font-title{
  font-size: 2rem;
}

.resume-topics-names{
  font-size: 2rem;
}

.projects-div-resume{
  padding-left: 10px;
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .about-p{
    font-size: 3vw;
  }

  .progress-h1{
    text-align: left;
    margin-right: auto;
    margin-top: 11px;
  }

  .video-tag{
    margin-left: 7.5vw;
  }

  .video-div{
    background: #057aff;
    padding: 20px;
    margin-right:5vw;
  }

  .my-name{
    position: relative;
    bottom: 40vw;
    left: 5vw;
    font-size: 5vw;
    display: inline-block;
  }
  .resume-topics{
    font-size: 2rem;
  }

  .font-1-5{
    font-size: 1.3rem;
  }

  .font-title{
    font-size: 1.5rem;
  }

  .resume-topics-names{
    font-size: 1.5rem;
  }

  .resume-h5-div{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {

  .resume-h5-div{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .row{
    display: flex;
    /* max-width: 90%; */
    margin: 0;
    margin-top: 10px;
  }

  .column-left{
    /* width: 20%; */
    margin-top: 10px;
    flex: auto;
    margin-left: 10px;
  }

  .column-right{
    flex: auto;
    /* width: 70%; */
    margin-top: 1em;
  }

  .about-p{
    font-size: 3vw;
  }

  .progress-h1{
    text-align: left;
    margin-right: auto;
    margin-top: 11px;
  }

  .my-progress{
    width: 50%;
    margin: auto;
    height: 3em;
    margin-right: 10px;
    margin-left: 23vw;
  }

  .white-bg{
    background: #e9ecef;
    color: #057aff;
  }

  .blue-bg{
    background: #057aff;
    color: white;
  }

  .video-tag{
    margin-left: 6vw;
  }

  .video-div{
    background: #057aff;
    margin-right: 5vw;
  }

  .footer-padding{
    padding-left: 4vw;
  }

  .my-name{
    position: relative;
    bottom: 40vw;
    left: 5vw;
    font-size: 5vw;
    display: inline-block;
  }

  .resume-topics{
    font-size: 2rem;
  }

  .font-1-5{
    font-size: 1.3rem;
  }

  .font-title{
    font-size: 1.5rem;
  }

  .resume-topics-names{
    font-size: 1.5rem;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  .progress-row{
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .tech-logo {
    padding: 2px;
  }

  .row{
    display: block;
    max-width: 100%;
  }
  .column-left{
    flex: none;
    width: 100%;
    padding: 10px;
    margin-bottom: 0px;
  }
  .column-right{
    flex: none;
    width: 100%;
    padding: 10px;
    margin-top: 0px;
  }

  .about-p{
    /* font-size: 6vw; */
  }

  .progress-h1{
    text-align: left;
    margin-right: auto;
    margin-top: 10px;
  }

  .my-progress{
    width: 50%;
    margin: auto;
    height: 3em;
    margin-right: 10px;
    margin-left: 5vw;
  }

  .experience-row{
    display: block;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .experience-div{
    width: auto;
    margin-right: 10px;
    margin-left: 10px;
    background: #057aff;
    padding: 2.5vw;
  }

  .experience-h1{
    margin-left: 5vw;
  }

  .white-bg-mobile{
    background: #e9ecef;
    color: #057aff;
  }

  .blue-bg-mobile{
    background: #057aff;
    color: white;
  }

  .experience-p{
    margin-left: 10vw;
    margin-right: 7vw;
  }

  .video-tag{
    width: 80vw;
    margin-left: 0;
  }

  .video-div{
    background: #057aff;
    padding: 15px;
    margin-right: 2vw;
  }

  .footer-padding{
    padding-left: 5vw;
  }

  .my-name{
    position: relative;
    bottom: 40vw;
    left: 7vw;
    font-size: 7vw;
    display: inline-block;
  }
  
  .resume-hr{
    border: 2px black solid;
    width: 80%;
  }

  .resume-h5-div{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .font-1-5{
    font-size: 1.3rem;
    padding-left: 1rem;
  }

  .resume-topics{
    font-size: 2rem;
  }

  .font-title{
    font-size: 1.5rem;
  }

  .resume-topics-names{
    font-size: 1.5rem;
  }

}

